export function isObject(value: any): boolean {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
}

export function isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
}

export function isArray(value: any): boolean {
    return Array.isArray(value);
}

export function isString(value: any): boolean {
    return typeof value === 'string' || value instanceof String;
}
