import { Component, Renderer2 } from '@angular/core';
import { LoginService } from './login/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'jamestown distributors';
  constructor(private loginService: LoginService, private renderer: Renderer2) {
    this.loginService.autoLogin();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadScript('https://www.googletagmanager.com/gtag/js');
      this.loadScript('https://www.google.com/recaptcha/enterprise.js?render=6Lcn1nscAAAAAHDuWvUVGbFCCw2AJW5G_7zZGNmz');
      this.loadScript('//script.crazyegg.com/pages/scripts/0099/9608.js');
      // this.loadScript('https://app.fairing.co/js/enquire-labs.js');
      // this.loadScript('https://static.zdassets.com/ekr/snippet.js?key=e0098b85-3a6f-4f2a-8ca7-c64fb8cdd9c1');
      // this.loadScript('https://www.paypal.com/sdk/js?client-id=AV4AWxbmniuTlcFt_XbsujMW-KHJZKSyrLhe8odZA85zDj_TdIqkhMxIaEnlKqQjBWCkGJR9IHYdDUUe&amp;commit=true');
    }, 3000);
  }
  loadScript(src: string): void {
    const script = this.renderer.createElement('script');
    script.src = src;
    script.defer = true;
    script.async = true;
    this.renderer.appendChild(document.body, script);
  }
}
