import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { CheckoutResolveService } from './checkout/checkout-resolve.service';
import { BaseComponent } from './base/base.component';

const routes: Routes = [{
  path: '',
  component: BaseComponent,
  children: [
    {
      path: 'login',
      loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
      path: 'home',
      loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule)
    },
    {
      path: 'page',
      loadChildren: () => import('./page/page.module').then(m => m.PageModule)
    },
    {
      path: 'product',
      loadChildren: () => import('./product/product.module').then(m => m.ProductModule)
    },
    // {
    //   path: 'userportal',
    //   loadChildren: './product/product.module#ProductModule'
    // },
    {
      path: 'cart',
      loadChildren: () => import('./cart/cart.module').then(m => m.CartModule)
    },
    {
      path: 'useraccount',
      loadChildren: () => import('./useraccount/useraccount.module').then(m => m.UseraccountModule)
    },
    {
      path: 'checkout',
      loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule),
      resolve: {
        cartData: CheckoutResolveService
      }
    },
    {
      path: 'organization',
      loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule)
    },
    {
      path: 'error',
      loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
    },
    {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full'
    },
    {
      path: 'page-not-found',
      component: PageNotFoundComponent
    },
    {
      path: '**',
      component: PageNotFoundComponent
    }
  ]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
