import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../login.service';
import { NotificationService } from './../../shared/services/notification.service';
import { Constants } from './../../shared/constants';
import { Router } from '@angular/router';
import { TitleMetadataService } from './../../shared/services/title-metadata.service';
import { BloomreachService } from '../../shared/services/bloomreach.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  message: string;
  privacyPolicy: string = Constants.privacyPolicy;
  termsPolicy: string = Constants.termsPolicy;

  registerForm = this.fb.group({
    Username: ['', [Validators.required, Validators.email, Validators.pattern(Constants.emailPattern)]],
    Password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(98), Validators.pattern(Constants.passwordPattern)]],
    ConfirmPassword: ['', Validators.required],
    familyName: ['', [Validators.required, Validators.maxLength(80)]],
    givenName: ['', [Validators.required, Validators.maxLength(80)]],
    phoneNumber: ['', [Validators.required, Validators.pattern(Constants.phonePattern), Validators.maxLength(10)]],
    zipCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern(Constants.zipPattern)]],
    notifyJDNews: [true],
    personalname: [''],
    poolData: ['']
  });

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private titleService: TitleMetadataService,
    private bloomreachService: BloomreachService) { }

  ngOnInit() {
    this.titleService.updateTitle('Create Account - Jamestown Distributors');
    if (!window['exponea']) {
      this.bloomreachService.loadBloomreachIntegration();
    }
  }

  // Create the user in Cognito and JD
  register(): void {
    if (this.registerForm.invalid) {
      return;
    }
    const formData = this.registerForm.getRawValue();
    formData.phoneNumber = `+1${formData.phoneNumber}`;
    formData.Username = formData.Username.toLowerCase();
    this.loginService.register(formData).then(
      response => {
        response.phone = response.phone.substr(2);
        this.loginService.createAccount(response).subscribe(resp => {
          const customerPropertiesData = {
            email: formData.Username,
            first_name: formData.givenName,
            last_name: formData.familyName,
          }
          this.bloomreachService.trackConsent(formData.notifyJDNews, 'Register Page', customerPropertiesData);
          if (this.registerForm.controls.notifyJDNews.value) {
          } else {
            this.registerForm.reset()
            setTimeout(() => {
              this.router.navigate(['']);
            }, 6000)
          }
          this.notificationService.showSuccess('User registered successfully. Verfication Link is sent. Please check your email');
          this.setToasterRoleAlert();
        }, err => {
          this.notificationService.showError('Please contact admin' + err);
          this.setToasterRoleAlert();
        });
      }, reject => {
        this.notificationService.showError(reject.message);
        this.setToasterRoleAlert();
      });

  }

  setToasterRoleAlert() {
    setTimeout(() => {
      const toastCloseButton: any = document.getElementsByClassName('toast-close-button');
      if (toastCloseButton.length) {

        toastCloseButton[0].addEventListener('click', () => {
          const emailInput: any = document.getElementById('txtEmailId');
          if (emailInput) {
            emailInput.focus()
          }
        })
      }
    }, 500)
  }
}
