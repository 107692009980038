import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from './../../shared/services/notification.service';
import { Constants } from './../../shared/constants';
import { Location } from '@angular/common';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  returnUrl: string;
  updatePasswordForm = false;
  forgotPasswordForm = this.fb.group({
    Username: ['', [Validators.required, Validators.email, Validators.pattern(Constants.emailPattern)]]
  });
  constructor(
    private fb: UntypedFormBuilder,
    private loginService: LoginService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params && params.email) {
        this.updatePasswordForm = true;
        this.forgotPasswordForm.patchValue({ Username: params.email });
        // this.forgotPassword();
        this.returnUrl = params.returnUrl;
      } else {
        this.updatePasswordForm = false;
      }
    });
  }

  forgotPassword(): void {
    if (this.forgotPasswordForm.controls.Username.value !== '' && this.updatePasswordForm === false) {
      this.loginService.forgotPassword(this.forgotPasswordForm.getRawValue()).then(
        response => {
          this.notificationService.showInfo('Verification Code sent. Please check your email!');
          this.router.navigate(['forgotpassword/verification'], { queryParams: { email: this.forgotPasswordForm.controls.Username.value } });
        }, reject => {
          if (reject.message === 'UserMigration failed with error Incorrect email address or password.') {
            this.notificationService.showError('Please check your email address');
          } else {
            this.notificationService.showError(reject.message);
          }
        });
    }
  }

  navigateBack(): void {
    if (this.route.queryParams) {
      this.route.queryParams.subscribe(queryParam => {
        const value = queryParam.refPage;
        if (value === 'login') {
          this.router.navigate(['/login']);
        } else {
          this.location.back();
        }
      });
    } else {
      this.location.back();
    }
  }
}
