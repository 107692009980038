import { Injectable } from '@angular/core';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Constants } from '../shared/constants';
import { StorageService } from '../shared/services/storage.service';
import { Router } from '@angular/router';
import { NotificationService } from '../shared/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  userPool: any;
  baseURL: string;
  user = new BehaviorSubject<any>(null);
  isLoggedOutValue = new BehaviorSubject(false);
  showLoginModal = new BehaviorSubject({ isShowLoginModal: false, url: '' });
  private tokenExpirationTimer: any;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private notificationService: NotificationService) {
    this.baseURL = Constants.baseURL; // base URL to Endpoint
    const poolData = {
      UserPoolId: Constants.cognito.userPoolId, // AWS user pool id
      ClientId: Constants.cognito.clientId, // AWS client id here
    };

    this.userPool = new CognitoUserPool(poolData);
  }

  // Update LoggedOut Value
  updateLoggedOutValue(value) {
    this.isLoggedOutValue.next(value);
  }

  // Update LoggedOut Value
  showLoginModalEvt(value) {
    this.showLoginModal.next(value);
  }

  getLoggedOutValue(): Observable<any> {
    return this.isLoggedOutValue.asObservable();
  }
  // Cognito Login
  login(authenticationData: any): Promise<any> {
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    if (authenticationData && authenticationData.Username) {
      authenticationData.Username = authenticationData.Username.toLowerCase();
      const userData = {
        Username: authenticationData.Username,
        Pool: this.userPool,
      };

      const cognitoUser = new CognitoUser(userData);

      // for user migrate flow, we need set this --Liang 20190430
      cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
      return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (result: any) => {
            resolve(result);
            this.handleAuthentication(result, true);
          },
          onFailure: (err) => {
            reject(err);
          },
        });
      });
    }
  }

  // Cognito Logout
  logout(navigateToLogin: boolean = false): void {
    const currentUser = this.userPool.getCurrentUser();
    if (currentUser != null) {
      currentUser.username = currentUser.username.toLowerCase();
      const userData = {
        Username: currentUser.username,
        Pool: this.userPool,
      };

      const cognitoUser = new CognitoUser(userData);
      if (cognitoUser != null) {
        cognitoUser.signOut();
        this.storageService.clear();
        this.storageService.setItem('subTotal', '0');
        this.user.next(null);
      }
    }
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
    if (navigateToLogin) {
      this.router.navigate(['']);
    }
  }

  // Cognito get current user
  getCurrentUser() {
    return this.userPool.getCurrentUser();
  }

  // Congnito Create Account
  register(registerData: any): Promise<any> {
    const attributeList = [];

    if (registerData && registerData.Username) {
      registerData.Username = registerData.Username.toLowerCase();
    }
    const dataEmail = {
      Name: 'email',
      Value: registerData.Username, // get from form field
    };

    const dataFamilyName = {
      Name: 'family_name',
      Value: registerData.familyName, // get from form field
    };

    const dataGivenName = {
      Name: 'given_name',
      Value: registerData.givenName, // get from form field
    };

    const dataPhoneNumber = {
      Name: 'phone_number',
      Value: registerData.phoneNumber, // get from form field
    };

    const attributeEmail = new CognitoUserAttribute(dataEmail);
    const attributeFamilyName = new CognitoUserAttribute(dataFamilyName);
    const attributeGivenName = new CognitoUserAttribute(dataGivenName);
    const attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);


    attributeList.push(attributeEmail);
    attributeList.push(attributeFamilyName);
    attributeList.push(attributeGivenName);
    attributeList.push(attributePhoneNumber);

    return new Promise((resolve, reject) => {
      return this.userPool.signUp(registerData.Username, registerData.Password, attributeList, null, (err, result) => {
        if (err) {
          return reject(err);
        }

        const body = {
          userSub: result.userSub,
          firstName: registerData.givenName,
          lastName: registerData.familyName,
          emailAddress: registerData.Username,
          phone: registerData.phoneNumber,
          zipCode: registerData.zipCode
        };
        resolve(body);
      });
    });
  }

  // Creates Account in JD
  createAccount(body: any): Observable<any> {
    // body = {
    //   'userSub': '82742b73-d282-4f63-9c76-9d118e7c2e8f',
    //   'firstName': 'Vinayak',
    //   'lastName': 'Bagi',
    //   'emailAddress': 'vinayak.bagi@silicus.com',
    //   'phone': '+14325551212',
    //   'zipCode': '34545'
    // }
    return this.http.post(this.baseURL + '/accounts/', body);
  }

  // Cognito Resend the Confirmation Code
  resendConfirmationCode(authenticationData: any): Promise<any> {
    if (authenticationData && authenticationData.Username) {
      authenticationData.Username = authenticationData.Username.toLowerCase();

      const userData = {
        Username: authenticationData.Username,
        Pool: this.userPool,
      };

      const cognitoUser = new CognitoUser(userData);

      return new Promise((resolve, reject) => {
        cognitoUser.resendConfirmationCode((error, result) => {
          if (error) {
            reject(error);
          }
          resolve(result);
        });
      });
    }
  }

  // Cognito Forgot Password
  forgotPassword(authenticationData: any): Promise<any> {
    if (authenticationData && authenticationData.Username) {
      authenticationData.Username = authenticationData.Username.toLowerCase();
      const userData = {
        Username: authenticationData.Username,
        Pool: this.userPool,
      };

      const cognitoUser = new CognitoUser(userData);
      // for user migrate flow, we need set this --Liang 20190430
      cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');

      return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
          onSuccess: (result) => {
            resolve(result);
          },
          onFailure: (err) => {
            reject(err);
          },
          inputVerificationCode() {
            resolve(true);
          }
        });
      });
    }
  }
  // Cognito Update Password by entering verification code
  updatePassword(authenticationData: any): Promise<any> {
    if (authenticationData && authenticationData.Username) {
      authenticationData.Username = authenticationData.Username.toLowerCase();

      const userData = {
        Username: authenticationData.Username,
        Pool: this.userPool,
      };

      const cognitoUser = new CognitoUser(userData);
      cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
      return new Promise<void>((resolve, reject) => {
        cognitoUser.confirmPassword(authenticationData.VerificationCode, authenticationData.Password, {
          onSuccess: () => {
            resolve();
          },
          onFailure: (err: Error) => {
            reject(err);
          }
        });
      });
    }
  }


  // Update Password in Cognito
  updateCognitoPassword(authenticationData: any): Promise<any> {
    const cognitoUser = this.userPool.getCurrentUser();
    return cognitoUser.getSession((err, session) => {
      if (err) {
        return;
      }
      return new Promise((resolve, reject) => {
        // tslint:disable-next-line: no-shadowed-variable
        return cognitoUser.changePassword(authenticationData.OldPassword, authenticationData.Password, (err, result) => {

          if (err) {
            return reject(err);
          }
          resolve(result);
        });
      });
    });
  }

  // Update User Account Info in Cognito
  updateCognitoAccountInfo(authenticationData: any): Promise<any> {
    const attributeList = [];
    const userCognitoData = {
      Name: 'given_name',
      Value: authenticationData.firstName
    };
    const lastNameCognitoData = {
      Name: 'family_name',
      Value: authenticationData.lastName
    };
    const emailCognitoData = {
      Name: 'email',
      Value: authenticationData.emailAddress
    };
    const phoneCognitoData = {
      Name: 'phone_number',
      Value: authenticationData.phone
    };
    const zipCodeCognitoData = {
      Name: 'custom:zipCode',
      Value: authenticationData.zipCode
    };
    return new Promise((resolve, reject) => {
      const attribute = new CognitoUserAttribute(userCognitoData);
      const attributeLastName = new CognitoUserAttribute(lastNameCognitoData);
      const attributeEmail = new CognitoUserAttribute(emailCognitoData);
      const attributePhone = new CognitoUserAttribute(phoneCognitoData);
      const attributeZipCode = new CognitoUserAttribute(zipCodeCognitoData);
      attributeList.push(attribute);
      attributeList.push(attributeLastName);
      attributeList.push(attributeEmail);
      attributeList.push(attributePhone);
      attributeList.push(attributeZipCode);
      const cognitoUser = this.userPool.getCurrentUser();
      return cognitoUser.getSession((err, session) => {
        if (err) {
          return;
        }
        // tslint:disable-next-line: no-shadowed-variable
        return cognitoUser.updateAttributes(attributeList, (err, result) => {
          if (err) {
            if (err.message.indexOf(Constants.JDFakeException)) {
              resolve(result);
            } else {
              return reject(err);
            }
          }
          resolve(result);
        });
      });
    });
  }

  // Get Account details from JD
  getAccountDetails(): Observable<any> {
    return this.http.get(`${this.baseURL}/accounts/account`);
  }

  // Creates Account in JD
  editAccount(body: any): Observable<any> {
    return this.http.put(this.baseURL + '/accounts/account', body);
  }

  validateZipcode(zipCode: any, state:any = undefined): Observable<any> {
    if(state !== undefined) {
      return this.http.get(`${this.baseURL}/validateZipcode?zipcode=${zipCode}&statecd=${state}`);
    } else {
      return this.http.get(`${this.baseURL}/validateZipcode?zipcode=${zipCode}`);
    }
  }

  private handleAuthentication(response: any, isFirstLoggedIn: boolean = false) {
    this.storageService.setItem('idToken', response.idToken.jwtToken);
    this.storageService.setItem('currentUser', response.idToken.payload);
    const expirationDate: Date = new Date(response.idToken.payload.exp * 1000);
    const expiresIn = expirationDate.getTime() - new Date().getTime();
    const user: any = response.idToken.payload;
    user._tokenExpirationDate = expirationDate;
    user.token = response.idToken.jwtToken;
    user.isFirstLoggedIn = isFirstLoggedIn;
    this.autoLogout(expiresIn);
    this.user.next(user);
  }

  autoLogin(): void {
    if (this.getCurrentUser()) {
      const userData: any = this.storageService.getItem('currentUser');
      this.user.next(userData);
      const date = new Date(userData.exp * 1000);
      const expirationDuration = date.getTime() - new Date().getTime();
      this.autoLogout(expirationDuration);
    }
  }

  autoLogout(expirationDuration: number) {
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    if(expirationDuration > 0) {
      this.tokenExpirationTimer = setTimeout(() => {
        // Refresh the session using refreshToken
        this.renewSessionWithRefreshToken();
      }, expirationDuration);
    } else {
      this.storageService.clear();
      this.storageService.setItem('subTotal', '0');
      this.user.next(null);
    }
  }

  getActiveSession(): boolean {
    const userData: any = this.storageService.getItem('currentUser');
    const date = new Date(userData.exp * 1000);
    if (date < new Date()) {
      return false;
    } else {
      return true;
    }
  }

  renewSessionWithRefreshToken(): Promise<any> {
    const userData = {
      Username: this.storageService.getItem('currentUser').email,
      Pool: this.userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          this.logout();
          this.storageService.setItem('returnURL', this.router.url);
          this.notificationService.notifyLogin(this.router.url);
          reject();
        } else {
          const refreshToken = session.getRefreshToken();
          cognitoUser.refreshSession(refreshToken,
            (error: any, result: any) => {
              if (error) {
                this.logout();
                this.storageService.setItem('returnURL', this.router.url);
                this.notificationService.notifyLogin(this.router.url);
                reject();
              } else {
                this.handleAuthentication(result);
                resolve(true);
              }
            });
        }
      });
    });

  }
}
